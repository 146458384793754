@import '../../../settings/colors';

.fileDropMessageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.875rem;
}

.fileDropMessageImg {
  width: min-content;
}

.fileDropMessageText {
  text-align: center;

  &.invalid {
    color: $flamingo;
  }
}
