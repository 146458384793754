@import '../../settings/colors';

.miniWrapper {
  width: 1.5rem;
  height: 1.5rem;
}

.bigWrapper {
  width: 4.5rem;
  height: 4.5rem;
}

.spinner {
  position: relative;
  height: 100%;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: calc(50% - (100% / 2));
    left: calc(50% - (100% / 2));
    border-radius: 50%;
    border-top: 4px solid $primary;
    border-left: 4px solid $primary;
    border-bottom: 4px solid $primary;
    border-right: 4px solid transparent;
    animation: spinner 1s linear infinite;
  }
}

.whiteSpinner {
  &:after {
    border-top: 4px solid $white;
    border-left: 4px solid $white;
    border-bottom: 4px solid $white;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
