@import '../../../settings/colors';
@import '../../../settings/typos';
@import '../../../settings/media-queries';

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @include mq900 {
    display: flex;
    flex-direction: column;
  }
}

.section,
.tableSection {
  width: 100%;
  background: $white;
}

.section {
  padding: 3.875rem 4.438rem;
}

.tableSection {
  padding: 1.5rem;
}

.wrapper {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  margin-top: 2rem;
}

.subTitle {
  font-size: $fontSizeSemiLarge;
  font-weight: 500;
  color: $primary;
  border-bottom: 1px solid $black\.1;
  padding-bottom: 1rem;
}

.input {
  padding: 0.813rem 0.938rem;
  background: $white;
  border: 1px solid rgba($black, 0.1);
  border-radius: 10px;
  outline: none;
}
