$mq__480: 30rem;
$mq__600: 37.5rem;
$mq__900: 56.25rem;
$mq__1200: 75rem;
$mq__1500: 93.75rem;
$mq__1800: 112.5rem;

@mixin mq480 {
  @media (max-width: #{$mq__480}) {
    @content;
  }
}

@mixin mq600 {
  @media (max-width: #{$mq__600}) {
    @content;
  }
}

@mixin mq900 {
  @media (max-width: #{$mq__900}) {
    @content;
  }
}

@mixin mq1200 {
  @media (max-width: #{$mq__1200}) {
    @content;
  }
}

@mixin mq1500 {
  @media (max-width: #{$mq__1500}) {
    @content;
  }
}

@mixin mq1800 {
  @media (max-width: #{$mq__1800}) {
    @content;
  }
}
