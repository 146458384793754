@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.grid,
.gridBorder {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include mq900 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.gridBorder {
  padding-bottom: 3.85rem;
  border-bottom: 1px solid rgba($mineShaft, 0.08);
}

.picNameWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;

  @include mq900 {
    padding-left: 0;
  }

  @include mq900 {
    margin-bottom: 1.85rem;
  }
}

.loadingWrapper {
  padding-left: 0.875rem;
  width: 9.375rem;
  height: 9.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarImage {
  border-radius: 50%;
  width: 9.375rem;
  height: 9.375rem;
  margin-left: 0.875rem;
}

.editImageWrapper {
  position: relative;
  width: 9.375rem;
  height: 9.375rem;
}

.editImage {
  position: absolute;
  right: -1rem;
  bottom: 0;
  content: '';
  height: 3rem;
  width: 3rem;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profileName {
  font-size: $fontSizeAlmostLarge;
  font-family: Merriweather, sans-serif;
  font-weight: 700;
  color: $thunder;
  margin-top: 1.125rem;
}

.infoWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.1rem;
  width: 100%;
}

.label {
  font-size: $fontSizeSmall;
  font-weight: 500;
  color: $primaryAlt;
  margin-bottom: 0.375rem;
}

.field {
  font-size: $fontSizeSmall;
  color: $black\.6;
}

.input {
  border: 1px solid rgba($black, 0.1);
  padding: 0.875rem 1rem;
  font-size: $fontSizeSmall;
  height: 2.5rem;
  border-radius: 0.375rem;
  width: 100%;
}

.btnContainer {
  display: flex;
  margin-top: 2rem;
}
