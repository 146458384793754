@import '../../../settings/colors';
@import '../../../settings/typos';
@import '../../../settings/media-queries';

.wrapper {
  display: flex;
  gap: 1.25rem;
  flex-direction: column;
  max-height: 22.25rem;
  overflow: hidden auto;
  width: 100%;

  @include mq1200 {
    max-height: 30rem;
  }
}

.documentsGroup {
  width: 100%;

  h1 {
    font-size: $fontSizeLarge;
    color: $primary;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    margin-bottom: 1.25rem;
  }
}
