@import '../../../settings/colors';
@import '../../../settings/sizes';
@import '../../../settings/media-queries';

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 1rem;
  height: $sidebarLogoHeight;
  border-bottom: 1px solid $primaryAlt;

  @include mq1200 {
    display: none;
  }
}

.title {
  color: $white;
  font-family: Merriweather, sans-serif;
  font-weight: bold;
  margin-top: 0.25rem;
  margin-left: 0.85rem;
}
