@import '../../settings/colors';
@import '../../settings/typos';

.legend {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.33rem;
}

.inPerson,
.online,
.hybrid {
  color: $primary;
  font-weight: 500;
  font-size: $fontSizeSmall;
  padding-left: 1.55rem;
  position: relative;

  &:before {
    width: 1.5rem;
    height: 1.5rem;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    border-radius: 14px;
  }
}

.inPerson {
  &:before {
    background: url('../../../assets/icons/inperson-icon.svg') no-repeat center center;
  }
}

.online {
  &:before {
    background: url('../../../assets/icons/online-icon.svg') no-repeat center center;
  }
}

.hybrid {
  &:before {
    background: url('../../../assets/icons/hybrid-icon.svg') no-repeat center center;
  }
}
