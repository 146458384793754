@import '../../../settings/colors';
@import '../../../settings/sizes';
@import '../../../settings/media-queries';

.noNewsMessage {
  color: $primary;
  font-weight: 500;
  padding-left: 1rem;
}

.cardsContainer {
  display: grid;
  gap: 4.75rem;
  grid-template-columns: repeat(2, 1fr);

  @include mq1800 {
    gap: 2rem;
    grid-template-columns: 1fr;
  }
}
