@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 3.875rem 4.438rem;
  font-family: Roboto, sans-serif;
  gap: 1.75rem;

  @include mq900 {
    padding: 1.875rem 2.438rem;
  }
  @include mq480 {
    padding: 2rem 1rem;
  }
}

.selectWrapper {
  display: grid;
  gap: 0.325rem 2rem;
  grid-template-columns: repeat(3, 1fr);

  @include mq1800 {
    grid-template-columns: repeat(2, 1fr);;
    gap: 0.75rem;
  }

  @include mq900 {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }
}

.selector {
  padding: 0.813rem 0.938rem;
  background: $white;
  border: 1px solid rgb(0 0 0 / 10%);
  border-radius: 10px;
  height: 3.125rem;
}

.sendButton {
  margin-left: auto;
}

.errorMessage {
  color: red;
}
