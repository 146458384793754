@import '../../settings/colors';
@import '../../settings/typos';

[type='checkbox'] {
  width: 1.4rem;
  height: 1.4rem;
  color: $primary;
  vertical-align: middle;
  appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  transition: background 300ms;
  cursor: pointer;
}

/* Pseudo element for check styling */

[type='checkbox']:before {
  content: '';
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #ccd3d8;
}

/* Checked */

[type='checkbox']:checked {
  background-color: currentcolor;
}

[type='checkbox']:checked:before {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.625rem;

  label {
    font-size: $fontSizeSmall;
  }

  input {
    margin-left: 0.5rem;
  }
}
