@import '../../../settings/sizes';
@import '../../../settings/colors';
@import '../../../settings/media-queries';

.bottomBar {
  display: none;

  @include mq1200 {
    display: flex;
    background: $white;
    width: 100%;
    height: $favoriteMobileBarHeight;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: space-evenly;
    align-items: center;
  }
}
