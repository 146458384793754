@import '../../settings/colors';
@import '../../settings/typos';

// TODO : Find out how to custom style react select without using styles in JS ?
.customSelect {
  &__menu {
    margin: 2rem auto !important;
  }

  &__option {
    background-color: red !important;
  }
}

.selectLabel {
  font-size: $fontSizeSmall;
  color: $thunder;
  letter-spacing: 0.1px;
  font-weight: 500;
}
