@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.button,
.buttonMobile {
  background-color: transparent;
  border: none;
}

.button {
  color: $white;
  height: 3rem;
  align-items: center;
  display: flex;
  gap: 0.75rem;
  padding: 0.75rem;
  cursor: pointer;

  p {
    font-family: Roboto, sans-serif;
    font-size: $fontSizeSmall;
    letter-spacing: 0.1px;
    opacity: 0.6;
  }

  @include mq1200 {
    display: none;
  }
}

.labelMobile {
  font-size: 0.625rem;
  font-weight: 600;
  color: $primaryAlt;
  text-transform: uppercase;
  margin-top: 0.33rem;
}

.labelMobileOpen {
  margin-top: -0.325rem;
}
