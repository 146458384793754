@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.sectionTitle {
  color: $white;
  font-family: Roboto, sans-serif;
  display: flex;
  gap: 1rem;
  font-size: $fontSizeNormal;
  align-items: center;
  font-weight: bold;
  padding: 2rem 0;
  cursor: pointer;
  list-style-type: none;
  user-select: none;
  border-bottom: 1px solid $dustyGray\.5;
}

details summary {
  &:after {
    content: url('../../../assets/icons/down-caret-icon-white.svg');
    transition: 0.5s;
    margin-left: auto;
  }
}

details[open] summary {
  &:after {
    transform: rotate(180deg);
  }
}

details[open] {
  .detailContent {
    animation: 0.5s linear forwards fadeInDown;
    margin-top: 2rem;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-1.25em);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.gridContent {
  columns: fill-available;
  column-count: 2;
  column-gap: 3rem;
  margin-top: 3rem;
  padding: 0 2rem 3rem;

  @include mq900 {
    display: flex;
    flex-direction: column;
  }
}

.gridCard {
  display: inline-block;
  background-color: $white;
  padding: 2.5rem;
  height: fit-content;
  margin: 0 0 3rem;
  width: 100%;
}

.gridCardTitle {
  color: $primaryAlt;
  font-family: Roboto, sans-serif;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: $fontSizeNormal;
  font-weight: bold;
  list-style-type: none;
  user-select: none;
  border-bottom: 1px solid rgba($dust, 0.2);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.expandMenuList {
  background-color: $primary;
  margin-top: 3rem;
  padding: 2rem 4rem 4rem;
}

.gridSubtitle {
  color: $primary;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: $fontSizeNormal;
  margin-bottom: 2rem;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.financialCenterInfoContainer {
  display: flex;
  background-color: $primary;
  justify-content: space-around;
  padding: 2rem;
  width: 100%;
  height: fit-content;
  color: $white;
  gap: 1rem;

  @include mq900 {
    flex-direction: column;
  }
}

.financialCenterInfos {
  display: flex;
  flex-direction: column;

  a {
    color: $turquoise;
    text-decoration: underline;
  }
}

.financialCenterInfoTitle {
  color: $white;
  font-family: Roboto, sans-serif;
  display: flex;
  gap: 1rem;
  font-size: $fontSizeNormal;
  font-weight: bold;
  list-style-type: none;
}
