@import '../../../settings/typos';
@import '../../../settings/colors';

.favMobileItem {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  border: none;
  width: 3rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.favMobileLabel {
  font-size: $fontSizeXXSmall;
  font-weight: 600;
  color: $primaryAlt;
  text-transform: uppercase;
  margin-top: 0.33rem;
}
