@import '../../../settings/colors';
@import '../../../settings/sizes';
@import '../../../settings/media-queries';

.container {
  display: none;

  @include mq1200 {
    display: flex;
    position: absolute;
    height: calc(100vh - ($headerHeight + $favoriteMobileBarHeight));
    background: $white;
    width: 100%;
    z-index: 1000;
    transform: translateY(-100%);
    transition: transform 1.5s ease-in-out;
    mix-blend-mode: normal;
    padding: 2.5rem 2.5rem 0;
  }
}

.containerOpen {
  width: $leftSidebarOpenWidth;

  @include mq1200 {
    transform: translateY($headerHeight);
    width: 100%;
  }

  p,
  h2 {
    display: block;
  }
}
