@import '../../settings/colors';
@import '../../settings/typos';

.toolBar {
  padding-bottom: 2.25rem;
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  border-bottom: 1px solid $black;
}

.toolBarMain {
  display: flex;
}

.leftChevron,
.rightChevron {
  width: 10.5px;
  height: 15.5px;
}

.leftChevron {
  margin-left: auto;
  margin-right: 1.5rem;
}

.rightChevron {
  transform: rotate(180deg);
}

.backLabel {
  margin-bottom: 0.5rem;
  font-size: $fontSizeSmall;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.leftMiniChevron {
  width: 8px;
  height: 12.5px;
  margin-right: 0.65rem;
}

.leftChevron,
.rightChevron,
.backLabel {
  cursor: pointer;
}

.monthLabel {
  font-weight: 700;
  font-size: $fontSizeSemiLarge;
  text-transform: capitalize;
}
