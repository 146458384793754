@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.contest {
  background: $white;
  padding: 2rem 4.438rem;
  margin-top: 0.625rem;
  height: 31.25rem;
  transition: height 0.5s ease-in-out;
  overflow: hidden;

  // TODO: Do a better animation handling
  @include mq1800 {
    height: 46.875rem;
  }

  @include mq480 {
    padding: 2rem 0.5rem;
  }
}

.collapsed {
  height: 125px;
}

.slider {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.track {
  display: flex;
  overflow: visible;
  transition: transform 1s ease-in-out;
}

.main {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-width: 100%;
  height: 100%;

  @include mq1800 {
    display: flex;
    flex-direction: column;
  }
}

.header {
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid $black\.5;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $dustyGray\.5;
  border: none;
  border-radius: 50%;
  padding: 0.75rem;
  height: 2.25rem;
  width: 2.25rem;
  transition: all 0.5s;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.rotate {
  transform: rotate(180deg);
}

.title {
  font-size: $fontSizeSemiLarge;
  color: $primaryAlt;
  font-weight: 700;
  margin-left: 1.25rem;
}

.rightButtons {
  display: flex;
  gap: 0.825rem;
  margin-left: auto;

  svg {
    min-width: 18px;
    min-height: 12px;
  }
}

.textContainer {
  padding: 0 1rem 3.125rem 3.125rem;
  display: flex;
  flex-direction: column;
  background: $white;
}

.contestTitle {
  color: $primary;
  font-weight: bold;
  font-size: $fontSizeLarge;
  font-family: Merriweather, sans-serif;
  margin-top: 0.5rem;
}

.contestImg {
  width: 40rem;
  height: 20rem;

  @include mq1800 {
    width: 100%;
  }
}

.contestDescription {
  font-family: Roboto, sans-serif;
  color: $dust;
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
}

.learnMoreButton {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  width: fit-content;
  gap: 0.75rem;

  a {
    color: $primary;
    font-weight: 700;
    letter-spacing: 0.15px;
  }
}

.deleteBtn {
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 0.75rem;

  p {
    color: $dust;
    font-weight: 500;
  }

  svg {
    fill: $flamingo;
    margin-right: 0.75rem;
  }
}
