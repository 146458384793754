.spinnerContainer {
    display: flex;
    width: 100%;
    justify-content: center;
}

.backdrop {
    inset: 0;
    z-index: 9999;
    background-color: rgb(0, 0, 0, 0.5);
    position: fixed;
    width: 100vw;
    height: 100vh;
}