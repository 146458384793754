@import '../../settings/colors';
@import '../../settings/sizes';
@import '../../settings/media-queries';

.header {
  display: none;

  @include mq1200 {
    position: relative;
    z-index: 10000;
    display: flex;
    width: 100%;
    height: $headerHeight;
    background-color: $primary;
    align-items: center;
    padding: 1.375rem 1rem;
    border-bottom: 1px solid $primaryAlt;
  }
}

.title {
  color: $white;
  font-family: Merriweather, sans-serif;
  font-weight: bold;
  margin-top: 0.25rem;
  margin-left: 0.85rem;
}

.routerWrapper {
  display: flex;
  align-items: center;
}
