@import '../../settings/typos';
@import '../../settings/colors';

.labelInput {
    font-size: $fontSizeSmall;
    color: $thunder;
    letter-spacing: .1px;
    font-weight: 500;
    cursor: pointer;
    padding-left: 10px;
}

.label {
    font-size: $fontSizeSmall;
    color: $thunder;
    letter-spacing: .1px;
    font-weight: 500; 
    margin-bottom: 4px;
}

.input {
    width: 1.4rem;
    height: 1.4rem;
    accent-color: $thunder;
}

.inputContainer {
    display: flex;
    align-items: center;
    margin: 4px 0;
}

.groupContainer {
    display: flex;
    flex-direction: column;
    gap: 0.15rem;
    margin-bottom: 1.75rem;
}