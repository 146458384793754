@import '../../../settings/colors';
@import '../../../settings/typos';

.table {
  width: 100%;
  text-align: left;
  font-size: $fontSizeSmall;
  letter-spacing: 0.1px;
}

.removeBtn {
  border: none;
  background: none;
  display: flex;

  svg {
    fill: #ef4e45;
  }
}
