@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

$tableHeight: 57.25rem;

.wrapper {
  background-color: $white;
  padding: 3.875rem 4.438rem;
  font-family: Roboto, sans-serif;
  overflow: auto;

  @include mq1200 {
    padding: 1.5rem 2.25rem;
  }
}

.noTransactions {
  color: $primary;
  font-weight: 500;
  padding-left: 1rem;
}

.table {
  width: 100%;
  text-align: left;
  font-size: $fontSizeSmall;
  max-height: $tableHeight;
  letter-spacing: 0.1px;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $tableHeight;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

thead {
  font-weight: 500;
}

thead,
tr {
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: $black;
    bottom: 0;
    position: absolute;
  }
}

tr {
  &:before {
    background-color: rgba($black, 0.1);
  }
}

td {
  padding: 1.25rem 2.5rem 1.25rem 0;
  color: rgba($black, 0.6);
  font-weight: 400;
  line-height: 1.5rem;
}

th {
  padding-bottom: 1.5rem;
}
