@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/sizes';
@import '../../settings/media-queries';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - $rightSidebarWidth);
  height: 4.5rem;
  background-color: $white;
  border-right: 1px solid rgba($dust, 0.2);
  z-index: 200;

  @include mq1200 {
    width: 100%;
  }
}
