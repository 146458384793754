@import '../../settings/colors';
@import '../../settings/typos';

.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: fit-content;

  p,
  a {
    color: $primaryAlt;
    font-family: Roboto, sans-serif;
    font-size: $fontSizeSmall;
  }

  a {
    text-decoration: underline;
  }
}

.whiteContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: fit-content;

  p,
  a {
    color: $white;
    font-family: Roboto, sans-serif;
    font-size: $fontSizeSmall;
  }

  a {
    text-decoration: underline;
  }
}

.nameText {
  font-weight: 700;
  line-height: 1.5rem;
}

.infoContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.removeButton {
  background: none;
  border: none;
}

.nameContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.5rem;
}
