html {
  font-family: Roboto, sans-serif;
  overflow: hidden;
}

body {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

p,
h1,
h2 {
  letter-spacing: 0.15px;
}

svg {
  cursor: pointer;
  min-width: fit-content;
}
