@import '../../settings/colors';
@import '../../settings/typos';

.navOption {
  width: 100%;
  color: $white;
  height: 3rem;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  padding: 0.75rem;

  p {
    font-family: Roboto, sans-serif;
    font-size: $fontSizeSmall;
    letter-spacing: 0.1px;
    opacity: 0.6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.iconContainer {
  border: none;
  width: 2rem;
  background-color: transparent;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.selectedNavOption {
  background: rgba($dustyGray, 0.2);
  border-radius: 1.875rem;

  p {
    opacity: 1;
  }

  svg {
    fill: $white;
  }
}

.navOptionsList {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-right: 0.5rem;
}

.navOptionsListClosed {
  margin-right: 0;
}

.bottomNavOptionsList {
  margin-top: auto;
  margin-bottom: 0.875rem;
}
