@import '../../settings/colors';
@import '../../settings/typos';

$tableHeight: 46.875rem;

.wrapper {
  margin-top: 1.25rem;
}

.table {
  width: 100%;
  height: $tableHeight;
  text-align: left;
  font-size: $fontSizeSmall;
  letter-spacing: 0.1px;
}

.spinnerContainer {
  height: $tableHeight;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.infoMessage {
  color: $black;
  font-size: $fontSizeSmall;
  font-weight: bold;
  padding: 0.375rem 0;
}

.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  color: $black;
  font-size: $fontSizeNormal;
}
