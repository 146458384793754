@import '../../settings/colors';
@import '../../settings/media-queries';

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;

  @include mq900 {
    grid-template-columns: 1fr;
  }
}

.skeleton {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba($dust, 0.2);
  width: 100%;
  padding: 1rem 0;
  gap: 30px;
  padding-left: 5px;
  padding-right: 50px;

  span:first-of-type {
    width: 35px;
    height: 35px;
  }

  span:last-of-type {
    width: 90%;
    height: 35px;
  }
}

.searchDropdown {
  position: absolute;
  top: -44.5rem;
  left: 0;
  right: 0;
  background-color: $white;
  width: 100%;
  height: 39.5rem;
  z-index: 10;
  transition: top 1s ease-in-out 0.25s;
  padding-left: 2rem;

  @include mq900 {
    padding-left: 1rem;
  }
}

.searchDropdownOpen {
  display: block;
  top: 4.5rem;
  height: 39.5rem;

  @include mq1500 {
    height: 45.5rem;
  }

  @include mq480 {
    height: 39.5rem;
  }
}

.documentWrapper {
  height: calc(100% - 4.063rem);
  overflow: auto;
  border-bottom: 1px solid rgba($dust, 0.2);

  @include mq480 {
    height: calc(100% - 6.25rem);
  }
}

.noResultText {
  color: $primary;
  padding: 0.5rem 2rem;
  padding-top: 4rem;
}
