@import '../../settings/colors';
@import '../../settings/typos';

.paginationFooter {
  display: flex;
  margin-left: auto;
  width: fit-content;
  gap: 0.5rem;
  margin-top: 0.5rem;
  align-items: center;
}

.button {
  padding: 0.25rem;
}

.paginationText {
  color: $black;
  font-size: $fontSizeNormal;
}

.menuButton {
  padding: 4px;
  background-color: transparent;
  border: none;
}

.menuButtonOpen {
  transform: rotate(180deg);
}

.filterIcon {
  padding: 4px;
  svg {
    cursor: default;
  }
}

.sortableIcon {
  padding: 4px;
  svg {
    cursor: default;
  }
}

.sortableIconDesc {
  svg {
    transform: rotate(90deg);
  }
}

.sortableIconAsc {
  svg {
    transform: rotate(-90deg);
  }
}

.numberInput {
  width: 75px;
}

.tableFooter {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  padding-top: 2rem;
}

.pageSizeLabel {
  cursor: pointer;
}

.pageSizeDropdown {
  display: inline-block;
  padding-left: 4px;
}
