@import '../../settings/colors';
@import '../../settings/typos';

.grid {
  display: grid;
  padding: 2rem 0;
  gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
}

.selectFilters {
  color: $primary;
  font-weight: 500;
  letter-spacing: 0.35px;
  padding: 0.25rem 0.75rem 0;
  border-bottom: 1px solid rgba($dust, 0.2);
}
