@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  label {
    margin-top: 1.25rem;
    font-family: Roboto, sans-serif;
    color: $thunder;
    display: flex;
    font-size: $fontSizeNormal;
    flex-direction: column;
    line-height: 1.8rem;
    letter-spacing: 0.1px;
    font-weight: 500;
    width: 100%;
  }

  h1 {
    font-family: Merriweather, sans-serif;
    font-weight: 700;
    line-height: 1.5rem;
    color: $thunder;
    font-size: $fontSizeXLarge;
  }

  h2 {
    font-family: Merriweather, sans-serif;
    font-weight: 900;
    line-height: 1.5rem;
    color: $thunder;
    font-size: $fontSizeXXLarge;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 3.5rem;
}

.input {
  border: 1px solid $dust;
  padding: 1rem;
  font-size: $fontSizeNormal;
  height: 3rem;
  border-radius: 0.375rem;
}

.forgottenPassword {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: $fontSizeSmall;
  line-height: 1.5rem;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
  color: $thunder;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
