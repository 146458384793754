@import '../../../settings/colors';
@import '../../../settings/typos';
@import '../../../settings/media-queries';

.wrapper {
  width: 100%;
  background: $white;
  padding: 3.875rem 4.438rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;

  @include mq1200 {
    padding: 2.5rem 2.25rem;
  }
}

.dateWrapper {
  width: 100%;
}
