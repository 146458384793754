@import '../../../settings/colors';
@import '../../../settings/typos';
@import '../../../settings/media-queries';

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 3.875rem 4.438rem;
  font-family: Roboto, sans-serif;
  gap: 1.75rem;
}
