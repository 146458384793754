@import '../../../../settings/colors';
@import '../../../../settings/typos';
@import '../../../../settings/media-queries';

.wrapper {
  width: 100%;
  background: $white;
  padding: 3.875rem 4.438rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.input {
  padding: 0.813rem 0.938rem;
  background: $white;
  border: 1px solid rgba($black, 0.1);
  border-radius: 10px;
  outline: none;
}

.dateWrapper {
  width: 100%;
}
