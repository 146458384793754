@import '../../../settings/colors';
@import '../../../settings/typos';
@import '../../../settings/media-queries';
@import '../../../settings/sizes';

.container {
  display: flex;
}

.cardBeingRemoved {
  opacity: 0.5;
}

.card {
  width: 100%;
}

.imgContainer {
  position: relative;
  height: 80%;
  width: 25%;
  overflow: hidden;

  @include mq900 {
    display: none;
  }
}

.cardImage {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.textContainer {
  padding: 0.5rem 3.125rem 3.125rem;
  display: flex;
  flex-direction: column;
  background: $white;
}

.newsTypeContainer {
  display: flex;
  justify-content: space-between;
}

.cardTitle {
  color: $primary;
  font-weight: bold;
  font-size: $fontSizeLarge;
  font-family: Merriweather, sans-serif;
}

.cardText {
  font-size: $fontSizeNormal;
  font-family: Roboto, sans-serif;
  color: $dust;
  line-height: 1.75rem;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.cardHeader {
  align-items: center;
  padding: 1.5rem 0 1.5rem 3.125rem;
  display: flex;
  height: 10%;
  gap: 1rem;
  background-color: rgba($white, 0.6);
  justify-content: space-between;

  &:hover {
    .removeBtn {
      opacity: 1;
    }
  }
}

.removeBtn {
  cursor: pointer;
  background: none;
  border: none;
  margin-right: 1rem;
  opacity: 0;
  transition: opacity 0.5s;

  svg {
    fill: $flamingo;
  }
}

.learnMoreButton {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  width: fit-content;
  gap: 0.75rem;

  a {
    color: $primary;
    font-weight: 700;
    letter-spacing: 0.15px;
  }
}

.cardStatusText {
  margin-left: auto;
}
