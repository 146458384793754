@import '../../settings/colors';
@import '../../settings/media-queries';

// keyframes don't seem to work
.container {
  pointer-events: auto;
  cursor: pointer;
  width: 32rem;
  transition: all 0.5s ease-in-out;

  @include mq600 {
    width: 100%;
  }

  svg {
    transition: all 0.5s ease-in-out;
    opacity: 0;
  }

  &:hover {
    opacity: 0.9;

    svg {
      opacity: 1;
    }
  }

  @keyframes brief-appear {
    0% {
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    90% {
      opacity: 1;
    }

    99% {
      // TODO: Weird hack to avoid removed snackbars taking space
      width: auto;
      height: auto;
    }

    100% {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
}
