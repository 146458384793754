@import '../../../settings/colors';
@import '../../../settings/typos';
@import '../../../settings/sizes';
@import '../../../settings/media-queries';

.container {
  width: $rightSidebarWidth;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  @include mq1200 {
    display: none;
  }
}

.documentGroups {
  background: $white;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.125rem;
  border-top: 1px solid rgba($dust, 0.2);
  padding: 2.5rem 2.5rem 0;
}

.settingsMenu {
  background: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba($dust, 0.2);
  height: 4.5rem;
  padding-right: 0.5rem;

  @include mq1200 {
    background: $primary;
  }
}

.spinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.profileLinkWrapper {
  display: flex;
  align-items: center;
}

.userName {
  font-size: $fontSizeSmall;
  color: $primary;
  letter-spacing: 0.1px;
  font-weight: 500;
  margin-right: auto;

  @include mq1200 {
    color: $white;
  }
}

.avatarImage {
  height: 2.875rem;
  width: 2.875rem;
  border-radius: 50%;
  margin-right: 0.875rem;
  margin-left: 1.875rem;
}
