@import '../settings/colors';

.container {
  position: relative;
}

.backdrop {
  background-color: $black\.5;
  position: absolute;
  inset: 0;
  z-index: 10001;
}
