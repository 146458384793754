@import '../../../settings/colors';
@import '../../../settings/sizes';
@import '../../../settings/media-queries';

.sidebar {
  height: 100vh;
  background-color: $primary;
  mix-blend-mode: multiply;
  width: $leftSidebarClosedWidth;
  transition: width 0.25s ease-in-out;

  @include mq1200 {
    position: absolute;
    background: $primary;
    width: 100%;
    z-index: 1000;
    transform: translateY(-100%);
    transition: transform 1.5s ease-in-out;
    mix-blend-mode: normal;

    p,
    h2 {
      display: block !important;
    }
  }

  p,
  h2 {
    display: none;
  }
}

.sidebarResizing {
  transition: none;
}

.sidebarOpen {
  width: $leftSidebarOpenWidth;

  @include mq1200 {
    transform: translateY($headerHeight);
    height: calc(100vh - ($headerHeight + $favoriteMobileBarHeight));
    width: 100%;
  }

  p,
  h2 {
    display: block;
  }
}

.navigationListsContainer {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  height: calc(100% - $sidebarLogoHeight);
}

.navigationListsContainerClosed {
  @include mq1200 {
    margin-left: 1rem;
    align-items: normal;
  }

  margin-left: 0;
  align-items: center;
}

.settingsMenuContainer {
  display: none;

  @include mq1200 {
    display: block;
  }
}
