@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.divContainer {
  position: relative;
  width: 66%;
  display: flex;
  background: url('../../../assets/img/dark-background.png') no-repeat center center;
  background-size: cover;

  &:before {
    width: 15rem;
    height: 33.375rem;
    content: '';
    position: absolute;
    z-index: 2;
    top: 30%;
    right: 29%;
    background: url('../../../assets/img/mobile-demo-image.png') no-repeat center center;
    border-radius: 14px;

    @include mq1500 {
      display: none;
    }
  }

  &:after {
    width: 37rem;
    height: 44rem;
    top: 6%;
    right: 0;
    content: '';
    position: absolute;
    z-index: 1;
    background: url('../../../assets/img/desktop-demo-image.png') no-repeat center center;

    @include mq1500 {
      display: none;
    }
  }

  h1 {
    font-family: Merriweather, sans-serif;
    font-weight: 700;
    font-size: $fontSizeLarge;
    line-height: 1.5rem;
    color: $white;
    margin-bottom: 1.875rem;
  }

  p {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: $fontSizeNormal;
    line-height: 1.5rem;
    color: $dustyGray;
    margin-bottom: 3rem;
  }

  @include mq1500 {
    width: 50%;
  }

  @include mq900 {
    display: none;
  }
}

.stackedImageContainer {
  position: relative;
  top: 0;
  left: 0;
  padding-top: 8rem;
  padding-bottom: 8rem;

  @include mq1500 {
    display: none;
  }
}

.signUpContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10rem;
  padding-left: 10rem;
  width: 50%;

  @include mq1500 {
    width: 100%;
  }
}

.backContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: absolute;
  top: 3.5rem;
  margin-bottom: 10rem;
  font-family: Roboto, sans-serif;
  font-size: $fontSizeSmall;
  color: $primaryAlt;
  font-weight: 700;
  line-height: 1.5rem;
}

.formContainer {
  width: 34%;
  position: relative;
  min-width: 20rem;
  padding-left: 8rem;
  padding-right: 8rem;
  background: $white;

  @include mq1500 {
    width: 50%;
  }

  @include mq1200 {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 2.25rem;
    width: 100%;
  }
}
