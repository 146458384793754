@import '../../settings/colors';
@import '../../settings/typos';

.newLabel {
  margin: 0.5rem 0;
  font-family: Roboto, sans-serif;
  font-size: $fontSizeSmall;
  font-weight: 500;
  border-radius: 2rem;
  padding: 0.25rem 0.75rem;
  width: fit-content;
  text-align: center;
  color: $white;

  &.peopleInsurance {
    background: $primaryAlt;
  }

  &.investments {
    background: $turquoise;
  }

  &.groupInsurance {
    background: $flamingo;
  }

  &.mortgage {
    background: $bayOfMany;
  }

  &.contest {
    background: $iron;
    color: $primary;
  }

  &.general {
    background: $dustyGray;
  }
}
