@import '../../settings/colors';
@import '../../settings/typos';

.button {
  position: relative;
  border: 0;
  background: $primary;
  color: $white;
  text-transform: uppercase;
  font-size: $fontSizeSmall;
  border-radius: 4px;
  padding: 0.25rem;
  cursor: pointer;
  width: fit-content;
}

.buttonMobile {
  background: $white;
  color: $primary;
  margin-left: auto;

  &:before {
    border-top: 8px solid $white;
  }
}
