@import '../../settings/colors';
@import '../../settings/media-queries';
@import '../../settings/typos';
@import 'react-big-calendar/lib/sass/styles';

.wrapper {
  background: $white;
  margin-top: 0.625rem;
  padding: 0 4.438rem 2.5rem;

  @include mq600 {
    padding: 1rem;
  }
}

.calendar {
  min-height: 48.75rem;
  color: $thunder;
  font-weight: 500;

  :global(.rbc-month-header) {
    background: $white;

    // Trick to visually remove borders of parents
    margin: -1px -1px 0;
  }

  :global(.rbc-date-cell) {
    text-align: left;
    padding-top: 0.75rem;
    padding-left: 0.75rem;
    margin-bottom: 0.5rem;
  }

  :global(.rbc-header) {
    letter-spacing: 0.1px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 1rem 1.5rem;

    @include mq600 {
      padding: 0.65rem 0.375rem;
    }
  }

  :global(.rbc-event) {
    min-height: 1.5rem;
    padding: 0.125rem 0.125rem 0.125rem 1.5rem;
  }

  :global(.rbc-header + .rbc-header) {
    border-left: none;
  }

  :global(.rbc-off-range-bg),
  :global(.rbc-today) {
    background: $white;
  }

  :global(.rbc-off-range) > :global(.rbc-button-link) {
    opacity: 0.3;
  }

  :global(.rbc-date-cell.rbc-now) > :global(.rbc-button-link) {
    background: $primaryAlt;
    border-radius: 3px;
    color: $white;
    font-size: $fontSizeSmall;
    padding: 1.5px 3.25px 2px;
  }
}

.peopleInsurance {
  background-color: $dustyGray !important;
}

.investments {
  background-color: $turquoise !important;
}

.groupInsurance {
  background-color: $flamingo !important;
}

.mortgage {
  background-color: $bayOfMany !important;
}

.contest {
  background: $iron !important;
  color: $primary !important;
}

.general {
  background-color: $dustyGray !important;
}

.inPerson,
.online,
.hybrid {
  position: relative;

  &:before {
    width: 1.5rem;
    height: 1.5rem;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    border-radius: 14px;
  }
}

.inPerson {
  &:before {
    background: url('../../../assets/icons/inperson-icon-white.svg') no-repeat center center;
  }
}

.online {
  &:before {
    background: url('../../../assets/icons/online-icon-white.svg') no-repeat center center;
  }
}

.hybrid {
  &:before {
    background: url('../../../assets/icons/hybrid-icon-white.svg') no-repeat center center;
  }
}
