@import '../../../settings/typos';
@import '../../../settings/colors';
@import '../../../settings/media-queries';

.container {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;

  span {
    font-family: Roboto, sans-serif;
    font-size: $fontSizeNormal;
    letter-spacing: 0.1px;
    line-height: 1.5rem;
    color: $black\.6;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 25rem;
  }

  svg {
    min-width: 1.563rem;
    min-height: 1.563rem;
  }
}

.button {
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  background: none;
  border: none;
  display: flex;

  @include mq1200 {
    height: 2rem;
    width: 2rem;
  }
}

.buttonLink {
  svg {
    fill: gray;
  }
}

.buttonGroup {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: auto;
  margin-right: 0.5rem;

  @include mq1200 {
    gap: 1rem;
  }
}
