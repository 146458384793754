@import '../../settings/colors';
@import '../../settings/typos';

.tool {
  background-color: $white;
  padding: 2.25rem 2.5rem 2.6rem;
}

.title {
  color: $primaryAlt;
  font-weight: 700;
  font-size: $fontSizeAlmostLarge;
  line-height: 1.875rem;
  font-family: Merriweather, sans-serif;
  margin: 0.75rem 0;
}

.description {
  color: $dust;
  font-size: $fontSizeSmall;
  font-weight: 400;
  margin-bottom: 1.25rem;
}

.descriptionWarning {
  color: $flamingo;
}

.subSectionWrapper {
  display: flex;
  align-items: center;
  margin: 0.75rem 0;
  cursor: pointer;
}

.subSectionLabel {
  padding-left: 0.938rem;
  font-weight: 700;
  font-size: $fontSizeNormal;
  color: $emperor;
}
