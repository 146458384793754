@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}


.wrapper {
  background-color: $white;
  padding: 2.5rem 3.875rem 3.875rem;
  font-family: Roboto, sans-serif;
  width: 50%;
  min-width: 1200px;

  @include mq1800 {
    width: 100%;
    min-width: 0px;
  }

  @include mq900 {
    padding: 2.5rem;
    min-width: 0px;
  }
}

.refreshWrapper {
  animation-name: refreshAnim;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-iteration-count: 3;
}

@keyframes refreshAnim {
  50% {box-shadow: 0px 0px 16px 10px rgba(0,0,0,0.5);}
}

.fieldWrapper {
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 2.375rem;
  border-bottom: 1px solid $black;
  margin-bottom: 3rem;

  @include mq600 {
    flex-direction: column;
  }
}

.personalInfo {
  font-size: $fontSizeSemiLarge;
  font-weight: 700;
  color: $primary;
}

.editProfile {
  color: $turquoise;
  font-size: $fontSizeSmall;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @include mq600 {
    margin-top: 0.85rem;
  }
}
