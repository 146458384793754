@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 1.125rem;
  margin-left: 1.125rem;
  margin-bottom: 1.5rem;

  @include mq480 {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
  }
}

.wrapper {
  background: $white;
  padding: 1.75rem 4rem;

  @include mq900 {
    padding: 1.75rem 1rem;
  }
}

.addButton {
  margin-left: auto;
  border: none;
  cursor: pointer;
}
