@import '../../settings/typos';

.tableContainer {
    width: 100%;
    margin: 1.75rem 0;
    th {
        text-align: left;
    }
}

.oneDocContainer {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
}

.noDoc {
    font-size: $fontSizeSmall;
    color: red;
}

.tableWrapper {
    overflow-x: auto;
}

.requiredCol {
    color: #ef4e45;
}
.tableField {
    min-width: 250px;
}
.checkboxTableField {
    min-width: 100px;
}