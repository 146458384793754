@import '../../../settings/colors';
@import '../../../settings/typos';

.table {
  width: 100%;
  text-align: left;
  font-size: $fontSizeSmall;
  letter-spacing: 0.1px;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
