@import '../../settings/media-queries';

.ressourceCardList {
  gap: 2.5rem;
}

.ressourceCardListGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @include mq1200 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq600 {
    grid-template-columns: 1fr;
  }
}

.ressourceCardListColumn {
  display: flex;
  flex-direction: column;
}
