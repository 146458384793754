@import '../../settings/colors';
@import '../../settings/typos';

.dateField {
  display: flex;
  flex-direction: column;
}

.inputLabel {
  font-size: $fontSizeSmall;
  color: $thunder;
  letter-spacing: 0.1px;
  font-weight: 500;
}

.datePicker {
  :global(.react-datetime-picker__wrapper) {
    border: 1px solid rgba($black, 0.1);
    border-radius: 5px;
  }
}
