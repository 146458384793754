@import '../../settings/sizes';
@import '../../settings/media-queries';

.layout {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: url('../../../assets/img/main-background.png') no-repeat center center;
  background-size: cover;

  @include mq1200 {
    flex-direction: column;
    position: relative;
  }
}

.wrapper {
  height: 100vh;
  width: calc(100% - $leftSidebarClosedWidth);

  @include mq1200 {
    width: 100%;
    position: absolute;
  }
}

.wrapperOpen {
  height: 100vh;
  width: calc(100% - $leftSidebarOpenWidth);

  @include mq1200 {
    width: 100%;
  }

  // Transition so that the layout sticks to the right of
  // the page on menu opening and no space on the right of layout/header is seen
  transition: width 0.25s ease-in-out;
}

.children {
  overflow-y: auto;
  height: calc(100vh - $headerHeight);
  width: calc(100% - $rightSidebarWidth);
  padding: 3rem 4.6rem;

  @include mq600 {
    padding: 3rem 2rem;
  }

  @include mq1200 {
    width: 100%;

    // "Complex" calc so that the children gets the perfect size in mobile
    height: calc(100vh - ($headerHeight + $searchBarHeight + $favoriteMobileBarHeight));
  }
}
