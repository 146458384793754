@import '../../settings/colors';
@import '../../settings/typos';

.bar {
  width: 100%;
  padding: 1.25rem 1.75rem;
  color: $white;
  display: flex;
  align-items: center;

  svg {
    min-width: 1.5rem;
    margin-right: 2rem;
  }

  a {
    text-decoration: underline;
  }
}

.globalBar {
  position: fixed;
  z-index: 1000000;
  height: 4.5rem;
}

.importantBar {
  background-color: $flamingo;
}

.warningBar {
  background-color: $seaBuckthorn;
}

.closeIcon {
  position: absolute;
  transform: translateY(-50%);
  right: 1.5rem;
  top: 50%;
  cursor: pointer;
}

.barLink {
  margin-left: 0.375rem;
}
