@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.container {
  background: $white;
  padding: 3.875rem 4.438rem;
  min-height: 31.25rem;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
}

.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @include mq900 {
    display: flex;
    flex-direction: column;
  }
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  font-size: $fontSizeSmall;
  color: $thunder;
  letter-spacing: 0.1px;
  font-weight: 600;
}
