@font-face {
  font-family: Roboto;
  src:
    url('../../assets/fonts/Roboto-Thin.woff2') format('woff2'),
    url('../../assets/fonts/Roboto-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src:
    url('../../assets/fonts/Roboto-Light.woff2') format('woff2'),
    url('../../assets/fonts/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src:
    url('../../assets/fonts/Roboto.woff2') format('woff2'),
    url('../../assets/fonts/Roboto.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src:
    url('../../assets/fonts/Roboto-Medium.woff2') format('woff2'),
    url('../../assets/fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src:
    url('../../assets/fonts/Roboto-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Merriweather;
  src:
    url('../../assets/fonts/Merriweather-Light.woff2') format('woff2'),
    url('../../assets/fonts/Merriweather-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Merriweather;
  src:
    url('../../assets/fonts/Merriweather.woff2') format('woff2'),
    url('../../assets/fonts/Merriweather.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Merriweather;
  src:
    url('../../assets/fonts/Merriweather-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Merriweather-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
