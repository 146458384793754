@import '../settings/colors';
@import '../settings/typos';

.title {
  color: $primary;
  font-size: $fontSizeXXLarge;
  font-weight: bold;
  font-family: Merriweather, sans-serif;
  padding-bottom: 0;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid rgba($dust, 0.2);
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 1.125rem;
  margin-left: 1.125rem;
  margin-bottom: 1.5rem;
}

.titlePadded {
  padding-bottom: 2rem;
}

.titleNoBorder {
  border: none;
}

.titleLinks {
  display: flex;
  place-content: space-evenly;
  padding-bottom: 20px;
  width: 240px;
}
