@import '../../settings/colors';
@import '../../settings/typos';

.inputWrapper {
  position: relative;
  min-height: 15.625rem;
  border: 2px dashed $turquoise;
  border-radius: 10px;
  background: rgba($turquoise, 0.05);
  color: $turquoise;
  z-index: 0;

  &:hover {
    border-style: solid;
  }

  &.invalid {
    border-color: $flamingo;
    background: rgba($flamingo, 0.05);
    color: $flamingo;
  }
}

.filesWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  inset: 0.875rem;
  overflow: hidden;
}

.input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.recommendedMsg {
  color: $dust;
  font-size: $fontSizeSmall;
  font-weight: 300;
  padding: 0.25rem 0.5rem;
}
