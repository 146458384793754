@import '../../settings/colors';
@import '../../settings/typos';

.wrapper {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: $fontSizeSmall;
  color: $thunder;
  letter-spacing: 0.1px;
  font-weight: 500;
}

.input {
  padding: 0.813rem 0.938rem;
  background: $white;
  border: 1px solid rgba($black, 0.1);
  border-radius: 10px;
  outline: none;
  width: 100%;
}
