@import '../../settings/colors';
@import '../../settings/media-queries';

.container {
  background-color: $white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  inset: 0;
  width: 40vw;
  height: min-content;
  margin: auto;
  padding: 1.5rem 2rem;
  border-radius: 8px;

  @include mq1200 {
    width: 85vw;
  }

  @include mq600 {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    justify-content: space-between;
  }
}

.closeBtn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;

  &:hover {
    svg {
      fill: $black;
    }
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
