@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.grid {
  display: grid;
  gap: 3.25rem;
  padding: 2.5rem;
  grid-template-columns: 1fr 1fr;
  border-left: 1px solid rgba($dust, 0.2);
  height: calc(100% - 4.063rem);

  @include mq900 {
    display: none;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleStyle {
  font-size: $fontSizeSmall;
  font-weight: 700;
  text-transform: uppercase;
  color: $primary;
  letter-spacing: 1.25px;
  margin-bottom: 2rem;
}

.checkWrap {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.inputWrapper {
  display: flex;
  align-items: center;
}
