@import '../../../settings/colors';
@import '../../../settings/typos';
@import '../../../settings/media-queries';

.item {
  display: flex;
  align-items: center;
  padding: 1.25rem 0;
  width: 100%;

  @include mq600 {
    justify-content: space-around;
  }
}

.name {
  margin-left: 0.5rem;
  font-weight: 500;
  font-size: $fontSizeNormal;
  color: $primary;

  @include mq1200 {
    max-width: 50%;
  }

  @include mq600 {
    max-width: 30%;
  }
}

.iconWrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @include mq600 {
    margin-left: 0;
    gap: 0.75rem;
  }
}

.button {
  width: fit-content;
  border: none;
  background: none;
  display: flex;
}

.buttonLink {
  svg {
    fill: gray;
  }
}
