@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.875rem;

  @include mq1500 {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mq1200 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq600 {
    grid-template-columns: 1fr;
  }
}
