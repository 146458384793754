@import '../../settings/colors';
@import '../../settings/typos';

.text {
  color: $primaryAlt;
  font-weight: 500;
  font-size: $fontSizeSemiLarge;
}

.title {
  color: $primaryAlt;
  font-weight: 700;
  font-size: $fontSizeLarge;
  text-align: center;
}
