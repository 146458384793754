@import '../../settings/colors';
@import '../../settings/typos';

.iframe {
  width: 100%;
  height: 80vh;
  border: none;
}

.deleteBtn {
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;

  p {
    color: $dust;
    font-weight: 500;
  }

  svg {
    fill: $flamingo;
    margin-right: 0.75rem;
  }
}
