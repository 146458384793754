.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
}

.icon {
  width: 75px;
}

.text {
  max-width: 90%;
}
