@import '../../../settings/colors';

.fileListWrapper {
  display: flex;
  justify-content: center;
  z-index: 2;
  overflow: hidden auto;
  border: 1px solid $black\.1;
  border-radius: 10px;
  padding: 0.5rem;
  width: 100%;
  max-width: 35rem;
  cursor: default;
}

.fileList {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fileWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  color: $thunder;
}

.fileName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fileButton {
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;

  svg {
    // TODO: Kinda hacky
    min-width: 24px;
  }
}

.fileSize {
  margin-left: auto;
}
