.table {
  width: 100%;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
