@import '../../settings/typos';
@import '../../settings/colors';

.tooltipText {
  visibility: hidden;
  opacity: 0;
  min-width: 7.5rem;
  background-color: $primary;
  color: $white;
  font-size: $fontSizeXSmall;
  text-align: center;
  padding: 0.25rem;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  transform: translateX(-50%);
  left: 0;
  top: 100%;
  position: absolute;
  z-index: 1;
  transition: all 0.5s ease-in-out;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    top: -4px;
    left: 50%;
    height: 0;
    border-bottom: 8px solid $primary;
    border-right: 0.5rem solid transparent;
    border-left: 0.5rem solid transparent;
  }
}

.tooltipContainer {
  position: relative;
  display: block;
}

.tooltipContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}
