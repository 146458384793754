@import '../../settings/colors';
@import '../../settings/typos';

.tabsList {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $black\.6;
  justify-content: space-evenly;
  padding-bottom: 2rem;
  padding-top: 2rem;
  background-color: $white;
}

.tabsListLeftAligned {
  justify-content: normal;

  .tabButton {
    margin: 0 0.938rem;
  }
}

.tabsNoBorder {
  border-bottom: none;
}

.tabButton {
  font-family: Roboto, sans-serif;
  font-weight: 700;
  color: $primaryAlt;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  outline: none;
  background-color: $white;
  transition: background-color 0.2s ease-in-out 0s;
  border-radius: 1.5rem;

  &:hover {
    background-color: $dustyGray\.5;
  }
}

.activeButton {
  background-color: $dustyGray\.5;
}
