@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.wrapper {
  height: 100%;
}

.tabsList {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba($dust, 0.2);
  padding: 0.75rem 0 0.75rem 3rem;
  background-color: $white;

  @include mq1500 {
    padding: 0.75rem;
  }

  @include mq600 {
    justify-content: center;
  }
}

.tabButton {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  background: $white;
  color: $dust;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  outline: none;
  transition: font-weight 0.2s ease-in-out 0s;
  display: flex;
  align-items: center;

  &:hover {
    font-weight: 700;
  }
}

.activeButton {
  font-weight: 800;
  color: $primaryAlt;
}

.resultNumber {
  color: $dust;
  background-color: $iron;
  border-radius: 16px;
  font-size: $fontSizeSmall;
  font-weight: 600;
  padding: 4px 8px;
  margin-left: 0.625rem;
  display: flex;
  align-items: center;

  & > svg {
    padding-bottom: 0.065rem;
    width: 1rem;
  }
}

.spinnerContainer {
  margin-left: 0.625rem;
}
