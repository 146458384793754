@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.documentItem {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba($dust, 0.2);
  width: 100%;
  padding: 1rem 0;
}

.documentTitle {
  margin-left: 0.313rem;
}

.documentInfo {
  display: flex;
  flex-direction: column;
  max-width: 28.25rem;

  p {
    color: $black\.6;
    font-size: $fontSizeSmall;
    margin-top: 0.313rem;
  }

  @include mq600 {
    max-width: 19.25rem;
  }

  @include mq480 {
    max-width: 13.25rem;
  }
}

.documentInfoHeader {
  display: flex;
  align-items: center;
}

.documentIconWrapper {
  margin-left: auto;
  width: 7.5rem;
  display: flex;
  gap: 1rem;

  svg {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.button {
  width: fit-content;
  border: none;
  background: none;
  display: flex;
}
