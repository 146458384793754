@import '../../settings/colors';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;

  svg {
    fill: $white;
  }

  &__success {
    background-color: $darkTurquoise;
  }

  &__info {
    background-color: $bayOfMany;
  }

  &__warning {
    background-color: $darkSeoBuckthorn;
  }

  &__danger {
    background-color: $darkFlamingo;
  }
}

.text {
  color: $white;
}
