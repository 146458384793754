@import '../../settings/colors';
@import '../../settings/typos';

.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 3rem;
  margin-top: -1rem;
}

.label {
  margin-left: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  color: $primaryAlt;
  font-weight: 600;
  font-size: $fontSizeXSmall;
}
