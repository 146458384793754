@import '../../settings/colors';
@import '../../settings/typos';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-left: 1px solid rgba($dust, 0.2);
}

.input {
  width: 100%;
  border: none;
  outline: none;
  padding: 1rem 3.5rem 1rem 1rem;
  z-index: 200;
  font-size: $fontSizeSemiLarge;
}

.input::placeholder {
  font-weight: 600;
}

.inputWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.searchIcon,
.closeIcon {
  z-index: 200;
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 1rem;
}

.closeIcon {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}
