@import '../settings/colors';
@import '../settings/typos';

.container {
  position: absolute;
  inset: 0;
}

.listContainer {
  inset: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
  flex-direction: column;
  overflow: hidden;
  gap: 0.5rem;
  margin: 0.75rem;
}
