@import '../../settings/colors';
@import '../../settings/typos';
@import '../../settings/media-queries';

.titleEquisoft {
  font-size: $fontSizeSemiLarge;
  font-weight: 700;
  color: $primary;
  margin-bottom: 16px;
}

.descEquisoft {
  font-size: $fontSizeSmall;
  color: $black\.6;
  margin-bottom: 32px;
}

.successEquisoft {
  margin-top: 32px;
  font-size: $fontSizeSmall;
  color: darkgreen;
  strong {
    font-weight: 700;
  }
}

