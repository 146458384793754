.formContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.sendButton {
  width: 20%;
  margin-left: auto;
}
