@import '../../settings/colors';
@import '../../settings/typos';

.button {
  position: relative;
  margin-right: 1.5rem;
  border: 0;
  background: none;
  text-transform: uppercase;
  font-size: $fontSizeSmall;
  border-radius: 4px;
  cursor: pointer;
}
