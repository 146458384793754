@import '../../settings/colors';
@import '../../settings/typos';

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.875rem;
}

.link {
  color: $primaryAlt;
  padding: 2rem;
  background-color: $white;
  font-size: $fontSizeLarge;
  font-weight: 600;
}
