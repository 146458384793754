@import '../../settings/colors';
@import '../../settings/typos';

.button {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  border: none;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: all 0.5s ease-in-out;
  font-size: $fontSizeNormal;
  padding: 1rem;
  letter-spacing: 0.15px;

  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
}

.primary {
  background-color: $turquoise;
  color: $white;

  &:hover {
    background-color: $darkTurquoise;
  }
}

.secondary {
  background: rgba($primaryAlt, 0.2);
  color: $primaryAlt;

  &:hover {
    background: rgba($primaryAlt, 0.4);
  }
}
