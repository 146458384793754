@import '../../settings/typos';
@import '../../settings/colors';

.label {
    font-size: $fontSizeSmall;
    color: $thunder;
    letter-spacing: .1px;
    font-weight: 500;
    cursor: pointer;
    padding-left: 10px;
}

.input {
    &:focus {
        box-shadow: 0 0 0px 2px rgba(0,0,0,0.2);
    }
}