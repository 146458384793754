$white: #fff;
$black: #000;
$black\.5: rgba($black, 0.5);
$black\.6: rgba($black, 0.6);
$black\.1: rgba($black, 0.1);
$primary: #463144;
$primaryAlt: #5a4358;
$thunder: #3f2c3e;
$iron: #e6e6e7;
$mineShaft: #212121;
$emperor: #505050;
$dust: #949494;
$dustyGray: #ae989f;
$dustyGray\.5: rgba($dustyGray, 0.5);
$turquoise: #29cbb2;
$darkTurquoise: #20a18b;
$shamrock: #3dcdb6;
$flamingo: #ef4e45;
$darkFlamingo: #ae170f;
$seaBuckthorn: #f79f38;
$darkSeoBuckthorn: #c46c08;
$cornFlowerBlue: #6f52f9;
$bayOfMany: #2a3992;
